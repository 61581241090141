import React, { useEffect, useState } from 'react';
import ApiHandler from '../../Components/Api/ApiHandler';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from './Shared/Header';
import Footer from './Shared/Footer';
import './css/Contact.css';
import emailValidator from 'email-validator';

const Title_F = (
    <React.Fragment>
        <Helmet>
            <title>NeauxPro – Contact</title>
        </Helmet>
    </React.Fragment>
)

const Header_F = (
    <React.Fragment>
        <h3>Contact</h3>
    </React.Fragment>
)


const Contact = ({ mobile }) => {
    const Api = new ApiHandler('site'),
        [emailName, setEmailName] = useState(''),
        [email, setEmail] = useState(''),
        [emailMsg, setEmailMsg] = useState(''),
        [validEmail, setValidEmail] = useState(null),
        [sendResult, setSendResult] = useState(null),
        [buttonEnabled, setButtonEnabled] = useState(true);

    async function validateEmail(email) {
        setValidEmail(emailValidator.validate(email));
        setEmail(email);
    }

    async function resetForm() {
        setEmailName('');
        setEmail('');
        setEmailMsg('');
        setValidEmail(null);
    }

    async function sendEmail() {
        let checkEmailStatus = setInterval(() => {
            sendResult == null ? setSendResult(false) : null;
        }, 5000);

        Api.Listener(data => {
            if (data.api == 'sendMail')
                if (data.data.sendMailResult == false) {
                    setSendResult(false);
                    setButtonEnabled(false);
                    clearInterval(checkEmailStatus);
                } else {
                    setSendResult(true);
                    resetForm();
                    clearInterval(checkEmailStatus);
                }
        });
        Api.SendEmail({ name: emailName, email: email, msg: emailMsg });
    }

    const Form_F = (
        <React.Fragment>
            <div id="CONTACT-Form">
                <p>You can always reach me on my socials.<br /> But if you want to talk business, use this form. </p>
                <div id="CONTACT-Form-Input">
                    {sendResult == true ? (<span id='CONTACT-emailSuccess'>Email Sent Successfully!</span>) : ''}
                    {sendResult == false ? (<span id='CONTACT-emailFail'>ERROR. Email me at: <a href='mailto:neauxpro@gmail.com'>neauxpro@gmail.com</a></span>) : ''}
                    <span>Name</span>
                    <input value={emailName} onChange={e => setEmailName(e.target.value)} type="text" name="name" />
                </div>
                <div id="CONTACT-Form-Input">
                    <span>E-mail</span>
                    <input value={email} onChange={e => validateEmail(e.target.value)} type="text" name="email" />
                </div>
                <span>Message</span>
                <textarea value={emailMsg} onChange={e => setEmailMsg(e.target.value)} maxLength="2000" />
                {(emailName.length > 0 && emailMsg.length > 10 && validEmail === true && buttonEnabled == true)
                    ? (<button onClick={sendEmail}>Send</button>) :
                    (<button disabled>Send</button>)}
            </div>
        </React.Fragment>
    )


    if (mobile === true) {
        return (
            <HelmetProvider>
                {Title_F}
                <Header mobile={mobile} />
                <div id="CONTACT-Body" className="SHARED-Content">
                    {Header_F}
                    {Form_F}
                </div>
                <Footer />
            </HelmetProvider>
        )
    } else {
        return (
            <HelmetProvider>
                {Title_F}
                <Header mobile={mobile} />
                <div id="CONTACT-Body" className="SHARED-Content SHARED-PageWrapper">
                    {Header_F}
                    {Form_F}
                </div>
                <Footer />
            </HelmetProvider>
        )
    }
};

export default Contact;