import openSocket from 'socket.io-client';
import IPData from 'ipdata';
import config from './Config.js';

const socket = openSocket(config.socketServer, { transports: ["websocket"], reconnection: true, reconnectionDelayMax: 5000, reconnectionAttempts: 60, timeout: 1000 });
const ipdata = new IPData(config.ipKey);

class ApiHandler {
    constructor(input) {
        this.webKey = input;
        this.ipAddress = null;
        this.apiId = (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));
    }

    Get(apiRoute, data = null) {
        let requestData = {
            id: this.apiId,
            ip: this.ipAddress,
            path: (window.location.pathname).slice(1),
            webkeyName: this.webKey,
            apiRoute: apiRoute,
            data: data,
        }

        if (requestData.ip === null) {
            ipdata.lookup().then(data => {
                this.ipAddress = data.ip;
                requestData.ip = data.ip;
                socket.emit('apiGet', requestData);
            });
        } else {
            socket.emit('apiGet', requestData);
        }
    }

    SendEmail( data) {
        let requestData = {
            id: this.apiId,
            ip: this.ipAddress,
            path: (window.location.pathname).slice(1),
            webkeyName: this.webKey,
            apiRoute: 'sendMail',
            data: data,
        }

        if (requestData.ip === null) {
            ipdata.lookup().then(data => {
                this.ipAddress = data.ip;
                requestData.ip = data.ip;
                socket.emit('apiSendMail', requestData);
            });
        } else {
            socket.emit('apiSendMailResult', requestData);
        }
    }

    Listener(cb) {
        socket.on('api', async (data) => {
            let trimData = {
                api: data.apiRoute,
                data: data.data
            }
            cb(trimData);
        });

        socket.on(`api_${this.apiId}`, async (data) => {
            let trimData = {
                api: data.apiRoute,
                data: data.data
            }
            cb(trimData);
        });
    };
}

export default ApiHandler;