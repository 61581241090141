import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTable } from 'react-table'
import ApiHandler from '../Api/ApiHandler';
import Header from './Shared/Header';
import Footer from './Shared/Footer';
import Helper from './Shared/Helper';
import NormalTable from './Components/Command-Table';
import MobileTable from './Components/Command-Table-Mobile';
import './css/Commands.css';


const Title_F = (
    <React.Fragment>
        <Helmet>
            <title>NeauxPro – Commands</title>
        </Helmet>
    </React.Fragment>
)

const Header_F = (
    <React.Fragment>
        <h3>NeauxBot Commands</h3>
    </React.Fragment>
)

const P1_F = (
    <React.Fragment>
        <p>NeauxBot is a super sophisticated futuristicly and advanced AI bot. You can use the commands below to interact with it in chat.</p>
    </React.Fragment>
)

const Commands = ({ mobile }) => {

    const Api = new ApiHandler('site');
    const [botCmds, setBotCmds] = useState(Helper.CommandLoading);
    const [soundBoard, setSoundBoard] = useState(null);

    useEffect(() => {
        Api.Listener((response) => {
            if (response.api === 'commands') {
                setBotCmds(response.data.botCommands);
                setSoundBoard(response.data.soundBoard);
            }
        });
        Api.Get('commands');
    }, []);


    if (mobile) {
        return (
            <HelmetProvider>
                {Title_F}
                <Header mobile={mobile} />
                <div id="COMMANDS-Body" className="SHARED-Content">
                    {Header_F}
                    {P1_F}
                    <div id="COMMANDS_MOBILE-BotCmdsTable">
                        <MobileTable botCmds={botCmds} />
                    </div>
                </div>
                <Footer />
            </HelmetProvider>
        )
    } else {

        return (
            <HelmetProvider>
                {Title_F}
                <Header mobile={mobile} />
                <div id="COMMANDS-Body" className="SHARED-Content SHARED-PageWrapper">
                    {Header_F}
                    {P1_F}
                    <div id="COMMANDS-BotCmdsTable">
                        <NormalTable botCmds={botCmds} />
                    </div>
                </div>
                <Footer />
            </HelmetProvider>
        )
    }
};

export default Commands;