import openSocket from 'socket.io-client';
import IPData from 'ipdata';
import config from './Config.js';

const socket = openSocket(config.socketServer, { transports: ["websocket"], reconnection: false, timeout: 1000 });
const ipdata = new IPData(config.ipKey);

class AuthedApiHandler {
    constructor(input) {
        this.isAuthed = false;
        this.isApiConnected = false;
        this.apiConnectionRetry = false;
        this.webKey = input;
        this.ipAddress = null;
        this.authId = (Math.random().toString(36).substring(2, 19) + Math.random().toString(36).substring(2, 19));
    }

    Authorize(cb) {
        var connectionCount = 10;
        let connectionStablizer = setInterval(() => {
            connectionCount--;
            socket.connect();
            this.connect();
            // console.log('Connection Stablizer: ' + connectionCount);
            if (connectionCount === 0)
                clearInterval(connectionStablizer);
        }, 1000);

        this.connectionListner(status => {
            clearInterval(connectionStablizer);
            cb(status);
        });

        this.connect();

        socket.on('disconnect', async () => {
            if (this.apiConnectionRetry === false) {
                //console.log('Disconnected.');
                this.isApiConnected = false;
                this.reconnect();
            }
        });
    }

    Get(apiRoute, data = null) {
        let requestData = {
            id: this.authId,
            ip: this.ipAddress,
            path: (window.location.pathname).slice(1),
            token: (window.location.search).slice(2),
            webkeyName: this.webKey,
            apiRoute: apiRoute,
            data: data,
        }

        if (requestData.ip === null) {
            ipdata.lookup().then(data => {
                this.ipAddress = data.ip;
                requestData.ip = data.ip;
                socket.emit('apiGet', requestData);
            });
        } else {
            socket.emit('apiGet', requestData);
        }
    }

    Post(apiRoute, data = null) {
        let requestData = {
            id: this.authId,
            ip: this.ipAddress,
            host: window.location.host,
            path: (window.location.pathname).slice(1),
            token: (window.location.search).slice(2),
            webkeyName: this.webKey,
            apiRoute: apiRoute,
            data: data,
        }

        if (requestData.ip === null) {
            ipdata.lookup().then(data => {
                this.ipAddress = data.ip;
                requestData.ip = data.ip;
                socket.emit('apiPost', requestData);
            });
        } else {
            socket.emit('apiPost', requestData);
        }
    }

    Listener(cb) {
        socket.on('api', async (data) => {
            let trimData = {
                api: data.apiRoute,
                data: data.data,
                config: new Map(JSON.parse(data.config))
            }
            cb(trimData);
        });
        socket.on(`api_${this.authId}`, async (data) => {
            let trimData = {
                api: data.apiRoute,
                data: data.data,
                config: new Map(JSON.parse(data.config))
            }
            cb(trimData);
        });
    }

    Status(cb) {
        if (this.isAuthed === true && this.isApiConnected === true && this.apiConnectionRetry === false) {
            cb(true);
        } else {
            cb(false);
        }
    }

    connect() {
        let requestData = {
            id: this.authId,
            ip: this.ipAddress,
            path: (window.location.pathname).slice(1),
            token: (window.location.search).slice(2),
            webkeyName: this.webKey,
        }

        if (requestData.ip === null) {
            ipdata.lookup().then(data => {
                this.ipAddress = data.ip;
                requestData.ip = data.ip;
                socket.emit('authRequest', requestData);
            });
        } else {
            socket.emit('authRequest', requestData);
        }
    }

    connectionListner(cb) {
        socket.on(`authResult_${this.authId}`, async (data) => {
            socket.off(`authResult_${this.authId}`);
            if (data.status === true) {
                this.isAuthed = true;
                this.isApiConnected = true;
                console.log('Connected.');
                cb(true);
            } else {
                this.isAuthed = false;
                this.isApiConnected = false;
                // console.log('Rejected.');
                cb(false);
            }
        });
    }

    reconnect() {
        let count = 0;
        this.apiConnectionRetry = true;
        const apiReconnect = setInterval(async () => {
            count++;
            if (this.isApiConnected === true || count === 10) {
                this.apiConnectionRetry = false;
                socket.close();
                clearInterval(apiReconnect);
            }

            if (this.isAuthed === true && this.isApiConnected === false) {
                socket.close();
                socket.open();
                this.connect();
            }

        }, (10000 - Math.floor(Math.random() * 3000)));

        this.connectionListner(() => {
            this.apiConnectionRetry = false;
            this.isApiConnected = true;
            clearInterval(apiReconnect);
            return;
        });
    }
}

export default AuthedApiHandler;