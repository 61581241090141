import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from './Shared/Header';
import Footer from './Shared/Footer';

const Title_F = (
    <React.Fragment>
                <Helmet>
                    <title>NeauxPro – Socials</title>
                </Helmet>
    </React.Fragment>
)

const Socials = ({mobile}) => {
    if (mobile === true) {
        return (
            <HelmetProvider>
                {Title_F}
                <Header mobile={mobile} />
                <div id="SOCIALS-Body" className="SHARED-Content">

                </div>
                <Footer />
            </HelmetProvider>
        )
    } else {
        return (
            <HelmetProvider>
                {Title_F}
                <Header mobile={mobile} />
                <div id="SOCIALS-Body" className="SHARED-Content SHARED-PageWrapper">

                </div>
                <Footer />
            </HelmetProvider>
        )
    }
};

export default Socials;