import React, { useState, useEffect } from 'react';
import AuthedApiHandler from '../../Api/AuthedApiHandler';
import { Transition, animated } from 'react-spring';
import Common from '../../Shared/Common';
import HudHelper from './HudHelper';
import HudShared from './HudShared';
import HudHeaderImg from './Assets/Header.png';
import './Hud.css';

const Hud = () => {
    // Authentication
    const [isAuthed, setAuth] = useState(false),
        [config, setConfig] = useState(new Map),
        [hudData, setHudData] = useState(HudShared.DefaultData),
        [indx, setIndx] = useState(Math.floor(Math.random() * 9)),
        [billboardData, setBillboardData] = useState(HudShared.Billboard.default),
        [displayBillboard, setDisplayBillboard] = useState(false),
        [leftPixelGradient] = useState(Common.LeftPixelGradient(17, 16)),
        [rightPixelGradient] = useState(Common.RightPixelGradient(8, 15));

    const analyzeBillboard = (billboardType) => {
        switch (billboardType) {
            case 'discord':
                setBillboardData(HudShared.Billboard.discord);
                break;
            case 'instagram':
                setBillboardData(HudShared.Billboard.instagram);
                break;
            case 'tiktok':
                setBillboardData(HudShared.Billboard.tiktok);
                break;
            case 'twitch':
                setBillboardData(HudShared.Billboard.twitch);
                break;
            case 'twitter':
                setBillboardData(HudShared.Billboard.twitter);
                break;
            case 'youtube':
                setBillboardData(HudShared.Billboard.youtube);
                break;
            default:
                setBillboardData(HudShared.Billboard.default);
                break;
        }

        if (setBillboardData !== HudShared.Billboard.default)
            showBillboard();
    }

    const showBillboard = () => {
        setTimeout(() => { setDisplayBillboard(true) }, 100);
        setTimeout(() => { setDisplayBillboard(false) }, 10000);
    }

    useEffect(() => {
        const Api = new AuthedApiHandler('stream');
        Api.Authorize(status => {
            setAuth(status);
        });

        Api.Listener(response => {
            if (response.config != undefined) {
                setConfig(response.config);
            }
            switch (response.api) {
                case 'billboard':
                    analyzeBillboard(response.data.billboardType);
                    break;

                case 'hudData':
                    setHudData(response.data.hudData);
                    break;

                default:
                    break;
            };
        });

        Api.Get('hudData');
        // If we don't get the data back the first time, send another request
        setTimeout(() => {
            if (hudData[0].Username === null)
                Api.Get('hudData');
        }, 3000);

        // Rotate hudData
        setInterval(() => {
            setIndx(indx => (indx + 1) % HudShared.DefaultData.length);
        }, 15000);
    }, []);

    if (isAuthed !== true) {
        return (
            <div id="HUD-Body">
                <div id="HUD-Wrapper">
                    <div id="HUD-Status" className='HUD-Status-Default'></div>
                    <div id="HUD-Main-Wrapper">
                        <div id="HUD-Main">
                            <div id="HUD-Title"></div>
                            <div id="HUD-Header">
                                <div id="HUD-RandomizerDiv">
                                    <React.Fragment>{leftPixelGradient}</React.Fragment>
                                </div>
                                <img src={HudHeaderImg} alt="NeauxPro-Header" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
    } else {
        return (
            <div id="HUD-Body">
                <div id="HUD-Wrapper">
                    <div id="HUD-Status" className='HUD-Status-Default'></div>
                    <Transition
                        items={displayBillboard}
                        from={{ opacity: 0 }}
                        enter={{ opacity: 1 }}
                        leave={{ opacity: 0 }}
                        config={{ duration: 750 }}>
                        {({ ...statusStyle }, displayBillboard) => displayBillboard && (
                            <animated.div style={statusStyle} id="HUD-Status" className='HUD-Status-Billboard'></animated.div>
                        )}
                    </Transition>
                    <div id="HUD-Main-Wrapper">
                        <div id="HUD-Main">
                            <div id="HUD-Title"></div>
                            <div id="HUD-Header">
                                <div id="HUD-RandomizerDiv">
                                    <React.Fragment>{leftPixelGradient}</React.Fragment>
                                </div>
                                <img src={HudHeaderImg} alt="NeauxPro-Header" />
                            </div>
                        </div>
                        <Transition
                            items={hudData[indx]}
                            keys={dataNode => dataNode.DataType}
                            from={{ opacity: 0 }}
                            enter={{ opacity: 1 }}
                            leave={{ opacity: 0 }}
                            config={{ duration: 1000 }}>
                            {({ ...dataNodeStyle }, dataNode) => dataNode && (
                                <animated.div id="HUD-Main" style={dataNodeStyle}>
                                    <animated.div id="HUD-Title">
                                        <animated.p style={dataNodeStyle}>{HudHelper.TitleAnalyzer(dataNode)}</animated.p>
                                    </animated.div>
                                    <animated.div id="HUD-Header">
                                        <div id="HUD-RandomizerDiv">
                                            <React.Fragment>{leftPixelGradient}</React.Fragment>
                                        </div>
                                        <img src={HudHeaderImg} alt="NeauxPro-Header" />
                                    </animated.div>
                                    <animated.div id="HUD-Data" style={dataNodeStyle}>
                                        <animated.div id="Hud-Data-Pixels" className={dataNodeStyle + (HudHelper.CustomAnalyzer(dataNode) === null ? ' HUD-Hide' : '')}>{rightPixelGradient}</animated.div>
                                        <animated.span className={dataNodeStyle + (HudHelper.CustomAnalyzer(dataNode) === null ? ' HUD-Hide' : '')}>{HudHelper.CustomAnalyzer(dataNode)}</animated.span>
                                    </animated.div>
                                    <animated.p id="HUD-Main-Text" style={dataNodeStyle}>{HudHelper.UsernameAnalyzer(dataNode)}</animated.p>
                                </animated.div>
                            )}</Transition>
                        <Transition
                            items={displayBillboard}
                            from={{ opacity: 0 }}
                            enter={{ opacity: 1 }}
                            leave={{ opacity: 0 }}
                            config={{ duration: 750 }}>
                            {({ ...billboardStyle }, displayBillboard) => displayBillboard && (
                                <animated.div id="HUD-Billboard" style={billboardStyle} className={billboardData.class}>
                                    <animated.div id="HUD-Billboard-Header">
                                        <div id="HUD-RandomizerDiv">
                                            <React.Fragment>{leftPixelGradient}</React.Fragment>
                                        </div>
                                        <img src={HudHeaderImg} alt="NeauxPro-Header" />
                                    </animated.div>
                                    <animated.div id="HUD-Billboard-Title" className={billboardData.class === 'HUD-Hide' ? 'HUD-Hide' : ''}>
                                        <animated.p style={billboardStyle}>{billboardData.title}</animated.p>
                                    </animated.div>
                                    <animated.p id="HUD-Billboard-Text" style={billboardStyle}>{billboardData.text}</animated.p>
                                </animated.div>
                            )}
                        </Transition>
                    </div>
                </div>
                <button onClick={() => { analyzeBillboard('instagram'); }}>ig</button>
                <button onClick={() => { analyzeBillboard('youtube'); }}>youtube</button>
                <button onClick={() => { analyzeBillboard('twitch'); }}>twitch</button>
                <button onClick={() => { analyzeBillboard('twitter'); }}>twitter</button>
                <button onClick={() => { analyzeBillboard('discord'); }}>discord</button>
                <button onClick={() => { analyzeBillboard('tiktok'); }}>tiktok</button>
                <button onClick={() => { analyzeBillboard('default'); }}>default</button>
            </div>
        );
    }
}

export default Hud;