module.exports = {
    EventDataAnalyzer: function (event) {
        let temp = null;
        switch (event.type) {
            case "bit":
            case "bits":
                temp = [{
                    id: event.id,
                    badgeColor: 'green',
                    badgeType: event.type.toUpperCase(),
                    username: event.username,
                    data: event.amount + ' ' + event.type,
                    msg: null,
                }];
                break;

            case "donation":
            case "tiltify":
                temp = [{
                    id: event.id,
                    badgeColor: 'purple',
                    badgeType: event.type.toUpperCase(),
                    username: event.username,
                    data: event.amount,
                    msg: event.message,
                }];
                break;

            case "follow":
                temp = [{
                    id: event.id,
                    badgeColor: 'yellow',
                    badgeType: event.type.toUpperCase(),
                    username: event.username,
                    data: null,
                    msg: null,
                }];
                break;

            case "host":
            case "raid":
                temp = [{
                    id: event.id,
                    badgeColor: 'blue',
                    badgeType: event.type.toUpperCase(),
                    username: event.username,
                    data: event.viewers + (event.viewers === 1 ? ' Viewer' : ' Viewers'),
                    msg: 'Last seen in: ' + event.category,
                }];
                break;

            case "merch":
                /*
                apiDataObj.data.username = Common.DB.NullOrEmptyData(data.from);
                apiDataObj.data.amount = data.formatted_amount;
                apiDataObj.data.message = data.message;
                */
                break;

            case "resub":
                temp = [{
                    id: event.id,
                    badgeColor: 'red',
                    badgeType: event.type.toUpperCase(),
                    username: event.username,
                    data: 'Plan: ' + event.sub_plan + ' Months: ' + event.months + (event.streak_months === null ? '' : (' Streak: ' + event.streak_months)),
                    msg: event.message,
                }];
                break;

            case "subscription":
                temp = [{
                    id: event.id,
                    badgeColor: 'orange',
                    badgeType: event.type.toUpperCase(),
                    username: event.username,
                    data: 'Plan: ' + event.sub_plan,
                    msg: event.message,
                }];
                break;

            case "points":
                temp = [{
                    id: Math.random().toString(36).substring(2, 15),
                    badgeColor: 'grey',
                    badgeType: event.type.toUpperCase(),
                    username: event.username,
                    data: event.data.toUpperCase(),
                    msg: event.custom,
                }];
                break;

            default:
                break;
        };
        return temp;
    }
}