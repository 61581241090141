const Shared = {
    DefaultData: [
        { DataType: "recentFollower", Username: null, Custom: null },
        { DataType: "recentSub", Username: null, Custom: null },
        { DataType: "recentResub", Username: null, Custom: null },
        { DataType: "recentCheer", Username: null, Custom: null },
        { DataType: "recentTip", Username: null, Custom: null },
        { DataType: "topCheer", Username: null, Custom: null },
        { DataType: "topTip", Username: null, Custom: null },
        { DataType: "followCount", Username: null, Custom: null },
        { DataType: "subCount", Username: null, Custom: null },
        //{ DataType: "topTiltify", Username: null, Custom: null },
        //{ DataType: "recentTiltify", Username: null, Custom: null }
    ],
    Billboard: {
        default: {
            class: 'HUD-Hide',
            title: '',
            text: ''
        },
        discord: {
            class: 'HUD-Billboard-Stye-Discord',
            title: '!discord',
            text: 'Join the Neauxbles Discord!'
        },
        instagram: {
            class: 'HUD-Billboard-Stye-Instagram',
            title: '!ig',
            text: 'Follow on Instagram: @NeauxPro'
        },
        tiktok: {
            class: 'HUD-Billboard-Stye-Tiktok',
            title: '!tiktok',
            text: 'Follow on TikTok: @NeauxPro'
        },
        twitch: {
            class: 'HUD-Billboard-Stye-Twitch',
            title: 'Join the Neauxbles!',
            text: 'Support the stream by following!'
        },
        twitter: {
            class: 'HUD-Billboard-Stye-Twitter',
            title: '!twitter',
            text: 'Follow on Twitter: @NeauxPro'
        },
        youtube: {
            class: 'HUD-Billboard-Stye-Youtube',
            title: '!yt',
            text: 'Subscribe to the YouTube Channel!'
        }
    }
};

module.exports = Shared;