import discord from './discord-60.png';
import header from './Header-NeauxPro.png';
import headerMobile from './Header-NeauxPro.png';
import instagram from './instagram-60.png';
import menuClosed from './menu-closed-30.png';
import menuOpen from './menu-open-30.png';
import tiktok from './tiktok-60.png';
import twitter from './twitter-60.png';
import youtube from './youtube-60.png';



const Images = {
    Discord: discord,
    Header: header,
    HeaderMobile: headerMobile,
    Instagram: instagram,
    MenuClosed: menuClosed,
    MenuOpen: menuOpen,
    Tiktok: tiktok,
    Twitter: twitter,
    Youtube: youtube,
}

export default Images;