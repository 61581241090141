import React, { useState, useEffect, useRef } from 'react';
import { Prompt } from 'react-router-dom';
import ReactPlayer from 'react-player';
import { GithubPicker } from "react-color";
import { SketchField, Tools } from 'react-sketch2';
import { useHotkeys } from 'react-hotkeys-hook';
import Common from '../Shared/Common';

const Telestrator = () => {
    import('./Telestrator.css');
    // Video Variables
    const [play, setPlay] = useState(false),
        [vol, setVol] = useState(1),
        [url, setUrl] = useState('https://clips-media-assets2.twitch.tv/AT-cm%7C1084648558.mp4'),
        [speed, setSpeed] = useState(1),
        [progress, setProgress] = useState(0),
        [seeking, setSeeking] = useState(false);

    // Sketch Variables
    const _sketch = useRef(null),
        _player = useRef(null),
        [color, setColor] = useState('grey'),
        [tool, setTool] = useState(Tools.Pencil),
        [width, setWidth] = useState(5);

    async function changeTool(toolName) {
        switch (toolName) {
            case 'line':
                setTool(Tools.Line);
                break;
            case 'rectangle':
                setTool(Tools.Rectangle);
                break;
            case 'circle':
                setTool(Tools.Circle);
                break;
            case 'select':
                setTool(Tools.Select);
                break;
            case 'pan':
                setTool(Tools.Pan);
                break;
            default:
                setTool(Tools.Pencil);
                break;
        }
    }

    async function changeColor(color) {
        setColor(color);
    }

    async function increaseWidth(increase) {
        if (increase) {
            width < 15 ? setWidth(width + 2) : '';
        } else {
            width > 1 ? setWidth(width - 2) : '';
        }
        let lastTool = tool;

        setTool(null);
        await Common.LoopWait(0.1);
        setTool(lastTool);
    }

    async function increaseSpeed(faster) {
        if (faster) {
            speed < 2 ? setSpeed(speed + 0.25) : '';
        } else {
            speed > 0.25 ? setSpeed(speed - 0.25) : '';
        }
    }

    async function WarnOnClose() {
        window.addEventListener("beforeunload", function (e) {
            var confirmationMessage = "";
            e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
            return confirmationMessage;              // Gecko, WebKit, Chrome <34
        });
    }

    useHotkeys('d', () => setPlay(!play), {}, [play]);
    useHotkeys('s', () => increaseSpeed(false), {}, [speed]);
    useHotkeys('f', () => increaseSpeed(true), {}, [speed]);
    useHotkeys('g', () => setSpeed(1), {}, [speed]);
    useHotkeys('z', () => vol == 1 ? setVol(0) : setVol(1), {}, [vol]);
    useHotkeys('q', () => increaseWidth(true), {}, [width]);
    useHotkeys('a', () => increaseWidth(false), {}, [width]);
    useHotkeys('w', (e) => _sketch.current.canUndo() ? _sketch.current.undo(e) : '', {}, [_sketch]);
    useHotkeys('r', (e) => _sketch.current.canRedo() ? _sketch.current.redo(e) : '', {}, [_sketch]);
    useHotkeys('e', (e) => _sketch.current.clear(e), {}, [_sketch]);

    async function handleSeekMouseDown() {
        setSeeking(true);
    };

    async function handleSeekChange(e) {
        setProgress(parseFloat(e.target.value));
    }

    async function handleSeekMouseUp(e) {
        setSeeking(false);
        _player.current.seekTo(parseFloat(e.target.value))
    }

    async function handleProgress(state) {
        //console.log('onProgress', state)
        // We only want to update time slider if we are not currently seeking
        if (!seeking)
            setProgress(state.played);
    }

    useEffect(() => {
        WarnOnClose();
    }, []);

    return (
        <div id="TELESTRATOR-Body">
            <Prompt message="Are you sure you want to leave?" />
            {/*<h3>NeauxPro - Telestrator Tool</h3>*/}
            <div id="TELESTRATOR-VideoControls">
                <button onClick={() => setPlay(!play)} >{play ? <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="40px" height="40px"><path d="M 8 4 C 6.895 4 6 4.895 6 6 L 6 24 C 6 25.105 6.895 26 8 26 L 10 26 C 11.105 26 12 25.105 12 24 L 12 6 C 12 4.895 11.105 4 10 4 L 8 4 z M 20 4 C 18.895 4 18 4.895 18 6 L 18 24 C 18 25.105 18.895 26 20 26 L 22 26 C 23.105 26 24 25.105 24 24 L 24 6 C 24 4.895 23.105 4 22 4 L 20 4 z" /></svg> : <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="40px" height="40px">    <path d="M 6 3 A 1 1 0 0 0 5 4 A 1 1 0 0 0 5 4.0039062 L 5 15 L 5 25.996094 A 1 1 0 0 0 5 26 A 1 1 0 0 0 6 27 A 1 1 0 0 0 6.5800781 26.8125 L 6.5820312 26.814453 L 26.416016 15.908203 A 1 1 0 0 0 27 15 A 1 1 0 0 0 26.388672 14.078125 L 6.5820312 3.1855469 L 6.5800781 3.1855469 A 1 1 0 0 0 6 3 z" /></svg>}</button>
                <div id="TELESTRATOR-VideoControls-SpeedControls">
                    <p>Speed: {speed}</p>
                    <button onClick={() => increaseSpeed(false)} ><svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="20px" height="20px"><path d="M 5 13 A 2.0002 2.0002 0 1 0 5 17 L 25 17 A 2.0002 2.0002 0 1 0 25 13 L 5 13 z" /></svg></button>
                    <button onClick={() => increaseSpeed(true)} ><svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="20px" height="20px"><path d="M 14.970703 2.9726562 A 2.0002 2.0002 0 0 0 13 5 L 13 13 L 5 13 A 2.0002 2.0002 0 1 0 5 17 L 13 17 L 13 25 A 2.0002 2.0002 0 1 0 17 25 L 17 17 L 25 17 A 2.0002 2.0002 0 1 0 25 13 L 17 13 L 17 5 A 2.0002 2.0002 0 0 0 14.970703 2.9726562 z" /></svg></button>
                </div>
                <div id="TELESTRATOR-VideoControls-VideoFile">
                    <input value={url} onChange={e => setUrl(e.target.value)} type="text" name="FileURL" />
                </div>
                <div id="TELESTRATOR-VideoControls-SeekBar">
                    <input
                        type='range' min={0} max={0.999999} step='any'
                        value={progress}
                        onMouseDown={handleSeekMouseDown}
                        onChange={handleSeekChange}
                        onMouseUp={handleSeekMouseUp}
                    />
                </div>
            </div>
            <div id="TELESTRATOR-Toolbox">
                <button onClick={(e) => _sketch.current.canUndo() ? _sketch.current.undo(e) : ''} ><svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="25px" height="25px"><path d="M 10 3 L 4 9 L 10 15 L 10 11 L 17 11 C 19.785124 11 22 13.214876 22 16 C 22 18.785124 19.785124 21 17 21 L 14 21 A 2.0002 2.0002 0 1 0 14 25 L 17 25 C 21.67453 25 25.431086 21.342021 25.84375 16.769531 A 2.0002 2.0002 0 0 0 26 16 C 26 11.053124 21.946876 7 17 7 L 10 7 L 10 3 z" /></svg></button>
                <button onClick={(e) => _sketch.current.canRedo() ? _sketch.current.redo(e) : ''} ><svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="25px" height="25px"><path d="M 20 3 L 20 7 L 13 7 C 8.053124 7 4 11.053124 4 16 A 2.0002 2.0002 0 0 0 4.1582031 16.779297 A 2.0002 2.0002 0 0 0 4.1582031 16.78125 C 4.5765992 21.347984 8.3295881 25 13 25 L 16 25 A 2.0002 2.0002 0 1 0 16 21 L 13 21 C 10.214876 21 8 18.785124 8 16 C 8 13.214876 10.214876 11 13 11 L 20 11 L 20 15 L 26 9 L 20 3 z" /></svg></button>
                <button className="TELESTRATOR-ExtendButton" onClick={(e) => _sketch.current.clear(e)}><svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="25px" height="25px"><path d="M 10 2 L 9 3 L 5 3 C 4.4 3 4 3.4 4 4 C 4 4.6 4.4 5 5 5 L 7 5 L 17 5 L 19 5 C 19.6 5 20 4.6 20 4 C 20 3.4 19.6 3 19 3 L 15 3 L 14 2 L 10 2 z M 5 7 L 5 20 C 5 21.1 5.9 22 7 22 L 17 22 C 18.1 22 19 21.1 19 20 L 19 7 L 5 7 z M 9 9 C 9.6 9 10 9.4 10 10 L 10 19 C 10 19.6 9.6 20 9 20 C 8.4 20 8 19.6 8 19 L 8 10 C 8 9.4 8.4 9 9 9 z M 15 9 C 15.6 9 16 9.4 16 10 L 16 19 C 16 19.6 15.6 20 15 20 C 14.4 20 14 19.6 14 19 L 14 10 C 14 9.4 14.4 9 15 9 z" /></svg></button>
                <hr />
                <button className={tool === Tools.Pencil ? 'TELESTRATOR-SelectedButton' : ''} onClick={() => changeTool('pencil')} ><svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="25px" height="25px"><path d="M 22.828125 3 C 22.316375 3 21.804562 3.1954375 21.414062 3.5859375 L 20 5 L 25 10 L 26.414062 8.5859375 C 27.195062 7.8049375 27.195062 6.5388125 26.414062 5.7578125 L 24.242188 3.5859375 C 23.851688 3.1954375 23.339875 3 22.828125 3 z M 18 7 L 6 19 L 7.5 19.5 L 8 22 L 10.5 22.5 L 11 24 L 23 12 L 18 7 z M 4.1523438 23.152344 L 3.0371094 26.308594 A 0.5 0.5 0 0 0 3 26.5 A 0.5 0.5 0 0 0 3.5 27 A 0.5 0.5 0 0 0 3.6816406 26.966797 L 6.8476562 25.847656 L 4.1523438 23.152344 z" /></svg></button>
                <button className={tool === Tools.Line ? 'TELESTRATOR-SelectedButton' : ''} onClick={() => changeTool('line')} ><svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="25px" height="25px"><path d="M 41.960938 5.9804688 A 2.0002 2.0002 0 0 0 40.585938 6.5859375 L 6.5859375 40.585938 A 2.0002 2.0002 0 1 0 9.4140625 43.414062 L 43.414062 9.4140625 A 2.0002 2.0002 0 0 0 41.960938 5.9804688 z" /></svg></button>
                <button className={tool === Tools.Circle ? 'TELESTRATOR-SelectedButton' : ''} onClick={() => changeTool('circle')} ><svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="25px" height="25px"><path d="M 25 2 C 12.32114 2 2 12.32114 2 25 C 2 37.67886 12.32114 48 25 48 C 37.67886 48 48 37.67886 48 25 C 48 12.32114 37.67886 2 25 2 z M 25 6 C 35.5171 6 44 14.4829 44 25 C 44 35.5171 35.5171 44 25 44 C 14.4829 44 6 35.5171 6 25 C 6 14.4829 14.4829 6 25 6 z" /></svg></button>
                <button className={tool === Tools.Rectangle ? 'TELESTRATOR-SelectedButton' : ''} onClick={() => changeTool('rectangle')} ><svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="25px" height="25px"><path d="M 5 8 A 5 5 0 0 0 3 17.576172 L 3 46.419922 A 5 5 0 0 0 5 56 A 5 5 0 0 0 9.5761719 53 L 54.421875 53 A 5 5 0 0 0 59 56 A 5 5 0 0 0 61 46.423828 L 61 17.580078 A 5 5 0 0 0 59 8 A 5 5 0 0 0 54.423828 11 L 9.578125 11 A 5 5 0 0 0 5 8 z M 9.5761719 15 L 54.421875 15 A 5 5 0 0 0 57 17.576172 L 57 46.419922 A 5 5 0 0 0 54.423828 49 L 9.578125 49 A 5 5 0 0 0 7 46.423828 L 7 17.580078 A 5 5 0 0 0 9.5761719 15 z" /></svg></button>
                <button className={tool === Tools.Select ? 'TELESTRATOR-SelectedButton' : ''} onClick={() => changeTool('select')} ><svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="25px" height="25px"><path d="M 9 3 A 1 1 0 0 0 8 4 L 8 21 A 1 1 0 0 0 9 22 A 1 1 0 0 0 9.796875 21.601562 L 12.919922 18.119141 L 16.382812 26.117188 C 16.701812 26.855187 17.566828 27.188469 18.298828 26.855469 C 19.020828 26.527469 19.340672 25.678078 19.013672 24.955078 L 15.439453 17.039062 L 21 17 A 1 1 0 0 0 22 16 A 1 1 0 0 0 21.628906 15.222656 L 9.7832031 3.3789062 A 1 1 0 0 0 9 3 z" /></svg></button>
                <button className={tool === Tools.Pan ? 'TELESTRATOR-SelectedButton' : ''} onClick={() => changeTool('pan')} ><svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="25px" height="25px"><path d="M 14.984375 2.9863281 A 1.0001 1.0001 0 0 0 14.207031 3.3769531 A 1.0001 1.0001 0 0 0 14.203125 3.3828125 L 11.292969 6.2929688 A 1.0001 1.0001 0 1 0 12.707031 7.7070312 L 14 6.4140625 L 14 11 A 1.0001 1.0001 0 1 0 16 11 L 16 6.4140625 L 17.292969 7.7070312 A 1.0001 1.0001 0 1 0 18.707031 6.2929688 L 15.791016 3.3769531 A 1.0001 1.0001 0 0 0 14.984375 2.9863281 z M 6.9804688 10.990234 A 1.0001 1.0001 0 0 0 6.2929688 11.292969 L 3.3808594 14.205078 A 1.0001 1.0001 0 0 0 3.3769531 15.792969 A 1.0001 1.0001 0 0 0 3.3828125 15.796875 L 6.2929688 18.707031 A 1.0001 1.0001 0 1 0 7.7070312 17.292969 L 6.4140625 16 L 11 16 A 1.0001 1.0001 0 1 0 11 14 L 6.4140625 14 L 7.7070312 12.707031 A 1.0001 1.0001 0 0 0 6.9804688 10.990234 z M 22.990234 10.990234 A 1.0001 1.0001 0 0 0 22.292969 12.707031 L 23.585938 14 L 19 14 A 1.0001 1.0001 0 1 0 19 16 L 23.585938 16 L 22.292969 17.292969 A 1.0001 1.0001 0 1 0 23.707031 18.707031 L 26.619141 15.794922 A 1.0001 1.0001 0 0 0 26.623047 14.207031 A 1.0001 1.0001 0 0 0 26.617188 14.203125 L 23.707031 11.292969 A 1.0001 1.0001 0 0 0 22.990234 10.990234 z M 14.984375 17.986328 A 1.0001 1.0001 0 0 0 14 19 L 14 23.585938 L 12.707031 22.292969 A 1.0001 1.0001 0 0 0 11.990234 21.990234 A 1.0001 1.0001 0 0 0 11.292969 23.707031 L 14.205078 26.619141 A 1.0001 1.0001 0 0 0 15.796875 26.617188 L 18.707031 23.707031 A 1.0001 1.0001 0 0 0 17.990234 21.986328 A 1.0001 1.0001 0 0 0 17.292969 22.292969 L 16 23.585938 L 16 19 A 1.0001 1.0001 0 0 0 14.984375 17.986328 z" /></svg></button>
                <hr />
                <p>Weight: {width}</p>
                <button onClick={() => increaseWidth(false)} ><svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="25px" height="25px"><path d="M 5 13 A 2.0002 2.0002 0 1 0 5 17 L 25 17 A 2.0002 2.0002 0 1 0 25 13 L 5 13 z" /></svg></button>
                <button onClick={() => increaseWidth(true)} ><svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="25px" height="25px"><path d="M 14.970703 2.9726562 A 2.0002 2.0002 0 0 0 13 5 L 13 13 L 5 13 A 2.0002 2.0002 0 1 0 5 17 L 13 17 L 13 25 A 2.0002 2.0002 0 1 0 17 25 L 17 17 L 25 17 A 2.0002 2.0002 0 1 0 25 13 L 17 13 L 17 5 A 2.0002 2.0002 0 0 0 14.970703 2.9726562 z" /></svg></button>
                <hr />
                <div id="TELESTRATOR-ColorPicker">
                    <div className="TELESTRATOR-color-preview" style={{ backgroundColor: color }} ></div>
                    <GithubPicker
                        colors={['#000000', '#333333', '#4d4d4d', '#666666', '#808080', '#999999', '#b3b3b3', '#cccccc', '#ffffff', '#9f0500', '#d33115', '#f44e3b', '#c45100', '#e27300', '#fe9200', '#fb9e00', '#fcc400', '#fcdc00', '#808900', '#b0bc00', '#dbdf00', '#194d33', '#68bc00', '#a4dd00', '#0c797d', '#16a5a5', '#68ccca', '#0062b1', '#009ce0', '#73d8ff', '#653294', '#7b64ff', '#aea1ff', '#ab149e', '#fa28ff', '#fda1ff']}
                        triangle={'hide'}
                        onChangeComplete={(color, event) => {
                            changeColor(color.hex);
                        }}
                        width={75}
                    />
                </div>
                <hr />
                <button className="TELESTRATOR-ExtendButton" onClick={() => { console.log(_sketch.current); console.log(_player) }} style={{ paddingBottom: '3px' }}>Log</button>
                <div className="TELESTRATOR-MuteStatus" style={{ backgroundColor: vol > 0 ? '#d33115' : '#68bc00', textAlign: 'center' }}>{vol > 0 ? 'Not Muted' : 'Muted'}</div>
            </div>
            <div id="TELESTRATOR-MediaWrapper">
                <div id="TELESTRATOR-Video">
                    <ReactPlayer
                        className='telestrator-react-player'
                        ref={_player}
                        width='1280px'
                        height='720px'
                        playbackRate={speed}
                        controls={false}
                        url={url}
                        playing={play}
                        onEnded={() => setPlay(false)}
                        volume={vol}
                        onProgress={handleProgress}
                        onError={e => console.log('onError', e)}
                    />
                </div>
                <div id="TELESTRATOR-Sketch">
                    <SketchField width='1280px'
                        height='720px'
                        ref={_sketch}
                        undoSteps={200}
                        tool={tool}
                        lineColor={color}
                        lineWidth={width} />
                </div>
            </div>
        </div>
    )

}

export default Telestrator;
