import React, { useEffect, useState } from 'react';
import { animated, Spring } from 'react-spring';
import Common from '../../Shared/Common';
import ReactPlayer from 'react-player';
import startupVideo from './Assets/StartupVideo.mov';
import interstellar from './Assets/Interstellar.mov';
import AuthedApiHandler from '../../Api/AuthedApiHandler';
import './Intro.css';

const Intro = () => {

    const [time, setTime] = useState({
        countdown: 180,
        display: null
    }),
        [loadUI, setLoadUI] = useState(false),
        [play, setPlay] = useState(true),
        [source, setSource] = useState(startupVideo),
        [grad] = useState(Common.RightPixelGradient(16, 1000));

    function formatTime() {
        if (time.countdown >= 0) {
            var m = Math.floor(time.countdown % 3600 / 60);
            var s = Math.floor(time.countdown % 3600 % 60);
            if (time.countdown <= 0) {
                setTime({ display: 'Starting Soon' });
            } else {
                if (time.countdown < 60) {
                    if (s < 10) {
                        setTime({ display: "0:0" + s })
                    } else {
                        setTime({ display: "0:" + s })
                    }
                } else {
                    if (time.countdown >= 60) {
                        if (s < 10) {
                            setTime({ display: m + ":0" + s })
                        } else {
                            setTime({ display: m + ":" + s })
                        }
                    }

                }
            }
        }
        return;
    }

    function startCountDown() {
        setInterval(() => {
            if (time.countdown > 0) {
                setTime({ countdown: (time.countdown--) });
                formatTime();
            }
        }, 1000);
    };

    useEffect(() => {
        startCountDown();
        // eslint-disable-next-line
    }, []);


    return (
        <div id="INTRO-Body">
            <div id="INTRO-Wrapper">
                <Spring
                    opacity={loadUI ? 1 : 0}
                    delay={500}>
                    {userInterface => <animated.div style={userInterface} id="INTRO-Countdown">
                        <animated.p>{time.display}</animated.p>
                    </animated.div>
                    }
                </Spring>
                <ReactPlayer
                    className='INTRO-Player'
                    width='1920px'
                    height='1080px'
                    url={source}
                    playing={play}
                    loop={loadUI}
                    volume={0}
                    onEnded={async () => {
                        setPlay(false);
                        setSource(interstellar);
                        setLoadUI(true);
                        setPlay(true);
                    }}
                    onError={e => console.log('onError', e)}
                />
            </div>
        </div>
    );

}

export default Intro;