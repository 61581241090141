import React, { useEffect, useState } from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <div id="FOOTER-Body">
            <p>Be Neauxble — &copy; NeauxPro 2021</p>
        </div>
    )
};

export default Footer;