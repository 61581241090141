import React, { useState, useEffect } from 'react';
import { Transition, animated } from 'react-spring';
import Confetti from 'react-dom-confetti';
import ReactPlayer from 'react-player';
import AuthedApiHandler from '../../Api/AuthedApiHandler';
import AlertHelper from './AlertHelper';
import AlertShared from './AlertShared';
import Audio from './Assets/Audio/Audio';
import Img from './Assets/Images/Images';
import './MinimalAlerts.css';
import './AlertShared.css';

const Alerts = () => {
    // Authentication
    const [isAuthed, setAuth] = useState(false),
    [config, setConfig] = useState(new Map);

    // Confetti Controls
    const [showConfetti, setConfetti] = useState(false);
    const displayConfetti = () => {
        setConfetti(true);
        setTimeout(() => setConfetti(false), 5000)
    }

    // Alert Variables
    const [showAlert, setShowAlert] = useState(false);
    const [alertData, setAlertData] = useState(AlertHelper.eventDataDefault);
    const [playAlertSound, setPlayAlertSound] = useState(false);
    const displayAlert = () => {
        setShowAlert(true);
        setPlayAlertSound(true);
        displayConfetti();
        setTimeout(() => setShowAlert(false), 8000);
    }

    const [showChannelPointEvent, setShowChannelPointEvent] = useState(false);
    const [playChannelPointSound, setPlayChannelPointSound] = useState(false);
    const [channelPointData, setChannelPointData] = useState(AlertShared.DefaultPoints);
    const displayChannelPointEvent = () => {
        setShowChannelPointEvent(true);
        setPlayChannelPointSound(true);
        setTimeout(() => setShowChannelPointEvent(false), 6500);
    }

    const [playCustomSound, setPlayCustomSound] = useState(false);

    useEffect(() => {
        const Api = new AuthedApiHandler('stream');
        Api.Authorize(status => {
            setAuth(status);
        });

        Api.Listener((response) => {
            if (response.config != undefined) {
                setConfig(response.config);
            }
            switch (response.api) {
                case 'event':
                    AlertHelper.EventAnalyzer(response.data, async result => {
                        console.log(result);
                        setAlertData(result);
                        displayAlert();
                        displayConfetti();
                    });
                    break;

                case 'channelpoints':
                    AlertHelper.ChannelPointAnalyzer(response.data, result => {
                        setChannelPointData(result);
                        displayChannelPointEvent();
                        if (result.type === 'confetti')
                            setTimeout(() => { displayConfetti(); }, 2500);
                    });
                    break;

                default:
                    console.log(response);
                    break;
            }

        });
    }, []);

    if (isAuthed !== true) {
        return (
            <div>
            </div>);
    } else {
        return (
            <div id="MINALERTS-Body">
                <div id="ALERTS-AudioPlayers">
                    <ReactPlayer
                        className='alertPlayer'
                        width='1px'
                        height='1px'
                        controls={false}
                        url={Audio.Default.url}
                        playing={playAlertSound}
                        volume={Audio.Default.vols}
                        onEnded={() => setPlayAlertSound(false)}
                        onError={e => console.log('onError', e)}
                    />
                    <ReactPlayer
                        className='channelPointPlayer'
                        width='1px'
                        height='1px'
                        controls={false}
                        url={Audio.ChannelPointSound.url}
                        playing={playChannelPointSound}
                        volume={Audio.ChannelPointSound.vol}
                        onEnded={() => setPlayChannelPointSound(false)}
                        onError={e => console.log('onError', e)}
                    />
                    <ReactPlayer
                        className='customPlayer'
                        width='1px'
                        height='1px'
                        controls={false}
                        url={Audio.Mario.url}
                        playing={playCustomSound}
                        volume={Audio.Mario.vol}
                        onEnded={() => setPlayCustomSound(false)}
                        onError={e => console.log('onError', e)}
                    />
                </div>
                <div id="MINALERTS-PointsWrapper">
                    <Transition
                        items={showChannelPointEvent}
                        from={{ width: '0px', marginLeft: '455px' }}
                        enter={{ width: '455px', marginLeft: '0px' }}
                        leave={{ width: '0px', marginLeft: '455px' }}
                        config={{ duration: 500 }}>
                        {({ ...showPointsTitleStyle }, showPointsTitle) => showPointsTitle && (
                            <animated.div id="ALERTSHARED-PointsTitleWrapper" style={showPointsTitleStyle}>
                                <div id="ALERTSHARED-PointsTitle">
                                    <img src={Img.Coin} />
                                    <animated.p>{channelPointData.cost} Coins Redeemed</animated.p>
                                </div>
                            </animated.div>
                        )}
                    </Transition>
                    <Transition
                        items={showChannelPointEvent}
                        from={{ width: '0px', marginLeft: '450px' }}
                        enter={{ width: '450px', marginLeft: '4px', delay: 2500 }}
                        leave={{ width: '0px', marginLeft: '450px' }}
                        config={{ duration: 500 }}>
                        {({ ...showPointsTextStyle }, showPointsText) => showPointsText && (
                            <animated.div id="ALERTSHARED-PointsText" style={showPointsTextStyle}>
                                <animated.span>{channelPointData.username} redeemed</animated.span>
                                <animated.span>{channelPointData.msg}</animated.span>
                            </animated.div>
                        )}
                    </Transition>
                </div>
                <div id="MINALERTS-EventsWrapper">
                    <Transition
                        items={showAlert}
                        from={{ width: 0, left: 0 }}
                        enter={{ width: 450, left: -225, opacity: 1 }}
                        leave={{ opacity: 0, config: { duration: 250 } }}
                        config={{ duration: 750 }}>
                        {({ ...alertStyleBackground }, showAlert) => showAlert && (
                            <div>
                                <animated.div id="ALERTSHARED-BackgroundTile" className="ALERTSHARED-BackgroundShared" style={alertStyleBackground}></animated.div>
                                <animated.div id="ALERTSHARED-Background" className="ALERTSHARED-BackgroundShared" style={alertStyleBackground}>
                                    <Transition
                                        items={showAlert}
                                        from={{ opacity: 0 }}
                                        enter={showAlert => async (next, cancel) => {
                                            await next({ delay: 1800 })
                                            await next({ opacity: 1, config: { duration: 500 } })
                                        }}
                                        config={{ duration: 750 }}>
                                        {({ ...alertTextStyle }, showAlert) => showAlert && (
                                            <animated.span id="ALERTSHARED-Text" style={alertTextStyle}>
                                                {alertData.username}
                                            </animated.span>
                                        )}
                                    </Transition>
                                    <Transition
                                        items={showAlert}
                                        from={{ opacity: 0, top: '25px', fontSize: '15px', lineHeight: '45px' }}
                                        enter={showAlert => async (next, cancel) => {
                                            await next({ opacity: 1, height: '40px', fontSize: '30px', lineHeight: '45px'  })
                                            await next({ delay: 1000 })
                                            await next({ height: '30px', top: '0px', fontSize: '20px', lineHeight: '35px', config: { duration: 500 } })
                                        }}
                                        config={{ duration: 750 }}>
                                        {({ ...alertTitleWrapperStyle }, showAlert) => showAlert && (
                                            <animated.div id="ALERTSHARED-Title" style={alertTitleWrapperStyle}>
                                                <Transition
                                                    items={showAlert}
                                                    from={{ opacity: 0 }}
                                                    enter={showAlert => async (next, cancel) => {
                                                        await next({ delay: 300, opacity: 1 })
                                                    }}
                                                    config={{ duration: 750 }}>
                                                    {({ ...alertTitleStyle }, showAlert) => showAlert && (
                                                        <animated.span style={alertTitleStyle}>
                                                            {alertData.title}
                                                        </animated.span>
                                                    )}
                                                </Transition>
                                            </animated.div>
                                        )}
                                    </Transition>
                                </animated.div>
                            </div>
                        )}
                    </Transition>
                </div>
                <div id="ALERTSHARED-Debug">
                    <button onClick={displayAlert}>Alert</button>
                    <button onClick={displayConfetti}>Confetti</button>
                    <button onClick={displayChannelPointEvent}>Points</button>
                    <p>Alerts - Authed</p>
                </div>
                <div id="ALERTSHARED-ConfettiRight"><Confetti active={showConfetti} config={AlertShared.ConfettiConfig.Right} /></div>
                <div id="ALERTSHARED-ConfettiLeft"><Confetti active={showConfetti} config={AlertShared.ConfettiConfig.Left} /></div>
            </div>
        );
    }
}
export default Alerts;