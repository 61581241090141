import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from './Shared/Header';
import Footer from './Shared/Footer';
import react from 'react';

const Title_F = (
    <React.Fragment>
        <Helmet>
            <title>NeauxPro – Home</title>
        </Helmet>
    </React.Fragment>
)

const Home = ({ mobile }) => {

    if (mobile === true) {
        return (
            <HelmetProvider>
                {Title_F}
                <Header mobile={mobile} />
                <div id="HOME_MOBILE-Body" className="SHARED-Content ">
                    <h3>Heyo, I'm NeauxPro</h3>
                </div>
                <Footer />
            </HelmetProvider>
        )
    } else {
        return (
            <HelmetProvider>
                {Title_F}
                <Header mobile={mobile} />
                <div id="HOME-Body" className="SHARED-Content SHARED-PageWrapper">
                    <h3>Heyo, I'm NeauxPro</h3>
                </div>
                <Footer />
            </HelmetProvider>
        )
    }
};

export default Home;