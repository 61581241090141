import React from 'react';
import { useTable } from 'react-table'
import '../css/Commands.css';

const Table = ({ botCmds }) => {
    const data = React.useMemo(
        () => botCmds
    )

    const columns = React.useMemo(
        () => [
            {
                Header: 'Command',
                accessor: 'Command', // accessor is the "key" in the data
            },
            {
                Header: 'UserLevel',
                accessor: 'UserLevel', // accessor is the "key" in the data
            },
            {
                Header: 'Timeout (Sec)',
                accessor: 'Timeout',
            },
            {
                Header: 'Description',
                accessor: 'Description',
            },
        ],
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data })

    return (
        <table {...getTableProps()}>
            <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps()}
                            >
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {
                    rows.map(row => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps([
                                                //  (cell.value === '!ig' ? { className: 'Admin' } : '')
                                            ])}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
            </tbody>
        </table>
    )
};

export default Table;