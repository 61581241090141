import React from "react";

const campaigns = [
    {
        key: 'wck',
        title: 'World Central Kitchen',
        raised: '$318.00',
        url: 'https://wck.org/',
        logoImg: '',
        info: (<React.Fragment></React.Fragment>)
    },
    {
        key: 'oar',
        title: 'Oraganization for Autism Research',
        raised: '$427.09',
        url: 'https://researchautism.org/',
        logoImg: '',
        info: (<React.Fragment></React.Fragment>)
    },
    {
        key: 'rainn',
        title: 'RAINN - Rape, Abuse and Incest National Network',
        raised: '$106.00',
        url: 'https://www.rainn.org/',
        logoImg: '',
        info: (<React.Fragment></React.Fragment>)
    },
    {
        key: 'tbp',
        title: 'The Bail Project',
        raised: '$186.00',
        url: 'https://bailproject.org/',
        logoImg: '',
        info: (<React.Fragment><b>Innocent Till Proven Guilty</b><br/><br/></React.Fragment>)
    },
    {
        key: 'rt',
        title: 'Rainforest Trust',
        raised: '$479.00',
        url: 'https://www.rainforesttrust.org/',
        logoImg: '',
        info: (<React.Fragment><b>New Zealand</b><br/><br/>Sitting in  overlooking one of the most peaceful lakes I've ever seen.</React.Fragment>)
    },
    {
        key: 'pfv',
        title: 'Paws For Veterans',
        raised: '$1,070.00',
        url: null,
        logoImg: null,
        info: (<React.Fragment><b>Doggos and Veterans</b><br/><br/>Two things people can always appreciate. Having friends that have fought to protect our freedoms, and the struggles some of them have adjusting back to everyday life. I decided that the Paws for Vets was a worthy cause that helped pair dogs who needed an owner with Vets that have PTSD.</React.Fragment>)
    },
];
export default campaigns;