const Shared = {
    ConfettiConfig: {
        Right: {
            angle: 125,
            spread: 60,
            startVelocity: 50,
            elementCount: 300,
            dragFriction: 0.05,
            duration: 7000,
            stagger: 5,
            width: "12px",
            height: "12px",
            //colors: ["#464545", "#2F2F2F", "#F54703", "#FF7518", "#5603ad", "#55e61b", "#dddddd", ]
            colors: ["#171717", "#004C8C", "#008CFF", "#008CFF", "#7AC3FF", "#F5F5F5"]
        },

        Left: {
            angle: 55,
            spread: 60,
            startVelocity: 50,
            elementCount: 300,
            dragFriction: 0.05,
            duration: 7000,
            stagger: 5,
            width: "12px",
            height: "12px",
            //colors: ["#464545", "#2F2F2F", "#F54703", "#FF7518", "#5603ad", "#55e61b", "#dddddd"]
            colors: ["#171717", "#004C8C", "#008CFF", "#008CFF", "#7AC3FF", "#F5F5F5"]
        }
    },

    DefaultPoints: {
        type: null,
        cost: null,
        id: null,
        username: null,
        custom: null,
    }
}

export default Shared;
