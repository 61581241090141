import React, { useState, useEffect } from 'react';
import { animated, Spring } from 'react-spring';
import Common from '../../Shared/Common';
import ReactPlayer from 'react-player';
import ReactLoading from 'react-loading';
import AuthedApiHandler from '../../Api/AuthedApiHandler';
import './Brb.css';

const Brb = () => {
    // Authentication
    const [isAuthed, setAuth] = useState(false),
        [config, setConfig] = useState(new Map),
        [clips, setClips] = useState([]),
        [play, setPlay] = useState(false),
        [currentClip, setCurrentClip] = useState(0),
        [rightPixelGradient] = useState(Common.RightPixelGradient(16, 16));

    function StartClips() {
        setPlay(true);
    }


    useEffect(() => {
        const Api = new AuthedApiHandler('stream');
        Api.Authorize(status => {
            setAuth(status);
        });

        Api.Listener(async response => {
            if (response.config != undefined) {
                setConfig(response.config);
            }
            if (response.api === 'clips') {
                console.log(response.data.clips.length + ' Clips Loaded.')
                setClips(response.data.clips);
                await Common.LoopWait(3);
                StartClips();
            }
        });
        Api.Get('clips');
    }, []);

    if (isAuthed !== true) {
        return (
            <div id="BRB-Body">
                <div id="BRB-Wrapper">
                    <div id="BRB-Loading-Wrapper">
                        <ReactLoading type="bars" color="#008aff" />
                        <h3>Loading Clip</h3>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div id="BRB-Body">
                <div id="BRB-Wrapper">
                    <Spring
                        from={{ opacity: 0 }}
                        to={{ opacity: 1 }}
                        delay={3000}
                        duration={750}>
                        {reactPlayer => <animated.div style={reactPlayer} id="BRB-React-Player-Wrapper">
                            <div id="BRB-React-Player-Title">
                                <p>{clips[currentClip] === undefined ? '' : clips[currentClip].title}</p>
                                {clips[currentClip] === undefined ? '' : (
                                    <div id="BRB-PixelGradient">
                                        {rightPixelGradient}
                                    </div>
                                )}
                            </div>
                            <ReactPlayer
                                className='react-player'
                                width='1333px'
                                height='750px'
                                url={clips[currentClip] === undefined ? '' : clips[currentClip].url}
                                playing={play}
                                volume={1}
                                onEnded={async () => {
                                    await Common.LoopWait(0.3);
                                    setCurrentClip(currentClip + 1);
                                }}
                                onError={e => console.log('onError', e)}
                            />
                        </animated.div>}
                    </Spring>
                    <div id="BRB-Loading-Wrapper">
                        <ReactLoading type="bars" color="#008aff" />
                        <h3>Loading Clip</h3>
                    </div>
                </div >
            </div>
        );
    }
};

export default Brb;