import React, { useEffect, useState } from 'react';
import { animated, Transition } from 'react-spring';
import Common from '../../Shared/Common';
import './Charity.css';

const Charity = () => {
    const Sponsors = [
        'melchncookie',
        'JawzDropping',
        'stormaggeddon',
        'ceeJay_23',
        'TheMarvelocity',
        'wormoSTEEZE',
        'freefr1es',
        'watchoutjackie',
        'Martin_Luther_Bling',
        'AngryB3ar',
        'isight45',
        'Reasonetc',
        'TheFreedomGamerZ'
    ];

    const [indx, setIndx] = useState(Math.floor(Math.random() * Sponsors.length));

    useEffect(() => {
        // Rotate Sponsor Name
        setInterval(() => {
            setIndx(indx => (indx + 1) % Sponsors.length);
        }, 7000);
    }, []);

    return (
        <div id="CHARITY_SPONSORS">
            <Transition
                items={Sponsors[indx]}
                keys={dataSponsor => dataSponsor}
                from={{ opacity: 0 }}
                enter={{ opacity: 1 }}
                leave={{ opacity: 0 }}
                config={{ duration: 1000 }}>
                {({ ...dataSponsorStyle }, dataSponsor) => dataSponsor && (
                    <animated.div id="CHARITY_SPONSORS-Text" style={dataSponsorStyle}>
                        <animated.p style={dataSponsorStyle}>{dataSponsor}</animated.p>
                    </animated.div>
                )}
            </Transition>
        </div>
    );
}

export default Charity;