const Helpers = {
    CustomAnalyzer: function (data) {
        let customData = null;
        switch (data.DataType) {
            case 'recentResub':
                customData = Number(data.Custom) + (Number(data.Custom) === 1 ? ' MONTH' : ' MONTHS');
                break;
            case 'recentCheer':
            case 'topCheer':
                customData = Number(data.Custom) + (Number(data.Custom) === 1 ? ' BIT' : ' BITS');
                break;
            case 'followCount':
                customData = null;
                break;
            case 'subCount':
                customData = null;
                break;
            case 'recentTip':
            case 'topTip':
            case 'topTiltify':
            case 'recentTiltify':
                customData = '$' + data.Custom;
                break;
            default:
                if (data.Custom == "")
                    customData = null;
                else
                    customData = data.Custom;
                break;
        };
        return customData;
    },

    TitleAnalyzer: function (data) {
        let title = null;
        switch (data.DataType) {
            case 'recentFollower':
                title = 'Newest Neauxble';
                break;
            case 'recentSub':
                title = 'Latest Sub';
                break;
            case 'recentResub':
                title = 'Latest Resub';
                break;
            case 'recentCheer':
                title = 'Latest Cheerer';
                break;
            case 'recentTip':
                title = 'Latest Tipper';
                break;
            case 'topCheer':
                title = 'Top Cheerer';
                break;
            case 'topTip':
                title = 'Top Tipper';
                break;
            case 'followCount':
                title = 'Neauxbles Follower Goal';
                break;
            case 'subCount':
                title = 'Neauxbles Sub Goal';
                break;
            case 'topTiltify':
                title = 'Top Charity Donation';
                break;
            case 'recentTiltify':
                title = 'Recent Charity Donation';
                break;
            default:
                title = data.Username;
                break;
        };
        return title;
    },

    UsernameAnalyzer: function (data) {
        let username = data.Username;
        switch (data.DataType) {
            case 'followCount':
                let followGoal = Math.ceil(Number(data.Custom) / 5) * 5;
                if (followGoal === Number(data.Custom))
                    followGoal += 5;
                username = data.Custom + '/' + followGoal;
                break;
            case 'subCount':
                let subGoal = Math.ceil(Number(data.Custom) / 5) * 5;
                if (subGoal === Number(data.Custom))
                    subGoal += 5;
                username = data.Custom + '/' + subGoal;
                break;
            default:
                break;
        }
        return username;
    },

}

export default Helpers;