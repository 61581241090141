import React, { useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './Components/Shared/Common';


// Website Components
import Home from './Components/Website/Home.js';
import ChangeLog from './Components/Website/ChangeLog.js';
import Charity from './Components/Website/Charity.js';
import Commands from './Components/Website/Commands.js';
import Contact from './Components/Website/Contact.js';
import Links from './Components/Website/Links.js';
import Neauxbles from './Components/Website/Neauxbles.js';
import OnlyFans from './Components/Website/OnlyFans.js';
import Socials from './Components/Website/Socials.js';
import Tips from './Components/Website/Tips.js';

// StreamAssistant Component
import StreamAssistant from './Components/StreamAssistant/StreamAssistant.js';

// StreamAssistant Component
import Telestrator from './Components/Telestrator/Telestrator.js';

// StreamAsset Components
import Alerts from './Components/StreamAssets/Alerts/Alerts.js';
import Brb from './Components/StreamAssets/Brb/Brb.js';
import CharitySponsors from './Components/StreamAssets/CharitySponsors/Charity.js';
import CleanHud from './Components/StreamAssets/Hud/CleanHud.js';
import Intro from './Components/StreamAssets/Intro/Intro.js';
import Hud from './Components/StreamAssets/Hud/Hud.js';
import MinimalAlerts from './Components/StreamAssets/Alerts/MinimalAlerts.js';
//import Outro from './Components/StreamAssets/Outro/Outro.js';
//import fw from './Components/StreamAssets/Alerts/Fireworks.js';


function App() {
  const [isMobile, setIsMobile] = useState(window !== undefined || window.innerWidth < 700 ? true : false);

  function updateDimensions() {
    if (window !== undefined)
      window.innerWidth < 700 ? setIsMobile(true) : setIsMobile(false);
  }

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [isMobile]);


  return (
    <BrowserRouter>
      <Switch>

        {/* Website */}
        <Route path='/home' render={() => <Home mobile={isMobile} />} />
        <Route path='/changelog' render={() => <ChangeLog mobile={isMobile} />} />
        <Route path='/charity' render={() => <Charity mobile={isMobile} />} />
        <Route exact path="/commands" render={() => <Commands mobile={isMobile} />} />
        <Route exact path="/contact" render={() => <Contact mobile={isMobile} />} />
        <Route path='/links' render={() => <Links mobile={isMobile} />} />
        <Route path='/neauxbles' render={() => <Neauxbles mobile={isMobile} />} />
        <Route path='/onlyfans' render={() => <OnlyFans mobile={isMobile} />} />
        <Route path='/socials' render={() => <Socials mobile={isMobile} />} />
        <Route path='/tips' render={() => <Tips mobile={isMobile} />} />

        {/* Website Redirects*/}
        <Route path='/discord' component={() => {
          window.location.href = 'https://discord.com/invite/D65wggZ';
          return null;
        }} />
        <Route path='/instagram' component={() => {
          window.location.href = 'https://instagram.com/neauxpro';
          return null;
        }} />
        <Route path='/live' component={() => {
          window.location.href = 'https://twitch.tv/neauxpro';
          return null;
        }} />
        <Route path='/tiktok' component={() => {
          window.location.href = 'https://www.tiktok.com/@neauxpro';
          return null;
        }} />
        <Route path='/twitter' component={() => {
          window.location.href = 'https://twitter.com/neauxpro';
          return null;
        }} />
        <Route path='/youtube' component={() => {
          window.location.href = 'https://www.youtube.com/channel/UC_F3pRDF6fU9S3CFeEqhkwg';
          return null;
        }} />

        {/* Stream Assistant */}
        <Route exact path="/streamassistant" component={StreamAssistant} />

        {/* Stream Assistant */}
        <Route exact path="/telestrator" component={Telestrator} />

        {/* //Stream Assets */}
        <Route exact path="/streamassets/alerts" component={Alerts} />
        <Route exact path="/streamassets/brb" component={Brb} />
        <Route exact path="/streamassets/charitysponsors" component={CharitySponsors} />
        <Route exact path="/streamassets/cleanhud" component={CleanHud} />
        <Route exact path="/streamassets/intro" component={Intro} />
        <Route exact path="/streamassets/hud" component={Hud} />
        <Route exact path="/streamassets/minalerts" component={MinimalAlerts} />
        {/* <Route exact path="/streamassets/outro" component={Outro} /> */}
        {/* <Route exact path="/streamassets/fw" component={fw} /> */}

        {/* // Website Default Handler */}
        <Route path='/' render={() => { return(<h2>Hey Neauxbles</h2>) }} />
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
