import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import ReactPlayer from 'react-player';
import AuthedApiHandler from '../Api/AuthedApiHandler';
import ChatSound from './Assets/chat-sound.mp3';
import ChannelPointSound from './Assets/channelpoints-sound.wav';
import Common from '../Shared/Common';
import ConfigMgr from '../Api/ConfigManager';
import Helper from './StreamHelper';
import './StreamAssistant.css';
import '../Shared/Common.css';

const StreamAssistant = () => {
    const [Api] = useState(new AuthedApiHandler('streamassistant'));

    const [isAuthed, setAuth] = useState(false),
        [config, setConfig] = useState(new Map),
        [apiStatus, setApiStatus] = useState(false),
        [customTweetText, setCustomTweetText] = useState(''),
        [eventList, setEventList] = useState([]),
        [lastMessage, setLastMessage] = useState({ timestamp: '', username: '' }),
        [playSound, setPlaySound] = useState(false),
        [soundFile, setSoundFile] = useState(''),
        [quote, setQuote] = useState('');

    async function playNewMsgPing(username) {
        setSoundFile(ChatSound);
        setLastMessage({ timestamp: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(), username: username });
        await Common.LoopWait(0.1);
        setPlaySound(true);
    }
    async function playChannelPointPing(username) {
        setSoundFile(ChannelPointSoundr);
        await Common.LoopWait(0.1);
        setPlaySound(true);
    }
    async function SendCustomTweet() {
        if (customTweetText !== '')
            Api.Post('customTweet', { msg: customTweetText });
    }

    async function SendQuote() {
        if (quote !== '') {
            Api.Post('addQuote', { quote: quote });
            Common.LoopWait(0.5);
            setQuote('');
        }
    }

    async function RestartDyno() {
        Api.Post('restartDyno');
    }

    async function handleCustomTweetResult(response) {
        if (response.data.result === true)
            setCustomTweetText('');
    }

    async function handleNewEvent(eventData) {
        let tempEvent = Helper.EventDataAnalyzer(eventData);
        setEventList((eventList) => [...tempEvent, ...eventList]);
    }

    async function WarnOnClose() {
        window.addEventListener("beforeunload", function (e) {
            var confirmationMessage = "";
            e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
            return confirmationMessage;              // Gecko, WebKit, Chrome <34
        });
    }

    useEffect(() => {
        Api.Authorize(status => {
            setAuth(status);
        });

        Api.Listener(async response => {
            console.log(response);
            if (response.config != undefined) {
                setConfig(response.config);
            }
            if (response.api === 'customTweet')
                handleCustomTweetResult(response);
            if (response.api === 'event')
                await handleNewEvent(response.data);
            if (response.api === 'channelpoints') {
                let temp = response.data;
                temp.data = response.data.type;
                temp.type = 'points';
                await handleNewEvent(temp);
                await playChannelPointPing();
            }
            if (response.api === 'newMsg')
                playNewMsgPing(response.data);
        });

        setInterval(() => {
            Api.Status(res => {
                setApiStatus(res);
            });
        }, 2500)

        WarnOnClose();
        Api.Get('getConfig');
        // eslint-disable-next-line
    }, []);

    if (isAuthed !== true) {
        return (
            <div></div>);
    } else {
        return (
            <div id="SA-Body">
                <Prompt
                    message="Are you sure you want to leave?"
                />
                <h3 className={apiStatus ? 'connected' : 'disconnected'}>NeauxPro - Stream Assistant</h3>
                <button id="SA-Status" onClick={RestartDyno}>Restart API</button>
                <ReactPlayer
                    className='react-player'
                    width='300px'
                    height='5px'
                    controls={false}
                    url={soundFile}
                    playing={playSound}
                    volume={1}
                    onEnded={() => setPlaySound(false)}
                    onError={e => console.log('onError', e)}
                />
                <fieldset id="SA-ChatPinger">
                    <legend>Chat Pinger</legend>
                    <p><b>Last Time:</b> {lastMessage.timestamp}</p>
                    <p><b>Last From:</b> {lastMessage.username}</p>
                    <button onClick={() => playNewMsgPing(lastMessage.username)}>Ping</button>
                </fieldset>

                <fieldset id="SA-CustomTweet">
                    <legend>Custom Tweet</legend>
                    <textarea value={customTweetText} onChange={e => setCustomTweetText(e.target.value)} maxLength="250" />
                    <button onClick={SendCustomTweet}>Submit</button>
                </fieldset>

                <fieldset id="SA-Quote">
                    <legend>Add Quote</legend>
                    <textarea value={quote} onChange={e => setQuote(e.target.value)} maxLength="140" />
                    <button onClick={SendQuote}>Submit</button>
                </fieldset>

                <fieldset id="SA-RecentEvents">
                    <legend>Recent Events</legend>
                    {eventList.slice(0, 10).map((e) =>
                        <div key={e.id}>
                            <div id="SA-EventWrap">
                                <div id="SA-EventTitle" className={e.msg === null ? 'no-msg' : ''}>
                                    <span id="SA-EventBadge" className={e.badgeColor}>{e.badgeType}</span>
                                    <span id="SA-EventTitle">{e.username}</span>
                                    <span id="SA-EventData">{e.data}</span>
                                </div>
                                {e.msg !== null ? <p id="SA-EventMsg">{e.msg}</p> : ''}
                            </div>
                        </div>)}
                </fieldset>
                <fieldset id="SA-SoundBoard">
                    <legend>SoundBoard</legend>

                </fieldset>

            </div >
        );
    }
};

export default StreamAssistant;
