import bits from './bits.ogg';
import point from './channelPointSound.mp3';
import def from './default.ogg';
import mario from './marioCourseClear.mp3';
import sub from './subAlert.mp3';

const defaultVol = 0.6;

const Audio = {
    Bits: {
        url: bits,
        vol: defaultVol,
    },
    ChannelPointSound: {
        url: point,
        vol: 0.5,
    },
    Default: {
        url: def,
        vol: defaultVol,
    },
    Mario: {
        url: mario,
        vol: defaultVol,
    },
    Sub: {
        url: sub,
        vol: 0.7,
    },
}

export default Audio;