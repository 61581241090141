import React, { useState, useEffect } from 'react';
import AuthedApiHandler from '../../Api/AuthedApiHandler';
import { Transition, animated } from 'react-spring';
import HudHelper from './HudHelper';
import HudShared from './HudShared';
import './CleanHud.css';

const CleanHud = () => {
    // Authentication
    const [isAuthed, setAuth] = useState(false),
        [config, setConfig] = useState(new Map),
        [hudData, setHudData] = useState(HudShared.DefaultData),
        [indx, setIndx] = useState(Math.floor(Math.random() * 9));

    useEffect(() => {
        const Api = new AuthedApiHandler('stream');
        Api.Authorize(status => {
            setAuth(status);
        });

        Api.Listener(response => {
            if (response.config != undefined) {
                setConfig(response.config);
            }
            switch (response.api) {
                case 'hudData':
                    setHudData(response.data.hudData);
                    break;

                default:
                    break;
            };
        });

        Api.Get('hudData');
        setInterval(() => {
            setIndx(indx => (indx + 1) % HudShared.DefaultData.length);
        }, 15000);
    }, []);

    if (isAuthed !== true) {
        return (
            <div id="CLEAN-HUD-Body">
                <div id="CLEAN-HUD-Wrapper">
                    <div id="CLEAN-HUD-Title"></div>
                    <div id="CLEAN-HUD-Text"></div>
                    <div id="CLEAN-HUD-Data"></div>
                </div>
            </div>);
    } else {
        return (
            <div id="CLEAN-HUD-Body">
                <Transition
                    items={hudData[indx]}
                    keys={dataNode => dataNode.DataType}
                    from={{ opacity: 0 }}
                    enter={{ opacity: 1 }}
                    leave={{ opacity: 0 }}
                    config={{ duration: 1000 }}>
                    {({ ...dataNodeStyle }, dataNode) => dataNode && (
                        <animated.div id="CLEAN-HUD-Wrapper">
                            <animated.div id="CLEAN-HUD-Title" style={dataNodeStyle}><animated.p style={dataNodeStyle}>{HudHelper.TitleAnalyzer(dataNode)}</animated.p></animated.div>
                            <animated.div id="CLEAN-HUD-Text" style={dataNodeStyle}><animated.p style={dataNodeStyle}>{HudHelper.UsernameAnalyzer(dataNode)}</animated.p></animated.div>
                            <animated.div id="CLEAN-HUD-Data" style={dataNodeStyle}><animated.span className={dataNodeStyle}>{HudHelper.CustomAnalyzer(dataNode)}</animated.span></animated.div>
                        </animated.div>
                    )}</Transition>
            </div>
        );
    }
}

export default CleanHud;