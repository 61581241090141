import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from './Shared/Header';
import Footer from './Shared/Footer';


const Title_F = (
    <React.Fragment>
        <Helmet>
            <title>NeauxPro – Neauxbles</title>
        </Helmet>
    </React.Fragment>
)

const Header_F = (
    <React.Fragment>
        <h3>The Neauxbles</h3>
    </React.Fragment>
)

const Neauxbles = ({ mobile }) => {
    if (mobile === true) {
        return (
            <HelmetProvider>
                {Title_F}
                <Header mobile={mobile} />
                <div id="NEAUXBLES-Body" className="SHARED-Content">
                    {Header_F}

                </div>
                <Footer />
            </HelmetProvider>
        )
    } else {
        return (
            <HelmetProvider>
                {Title_F}
                <Header mobile={mobile} />
                <div id="NEAUXBLES-Body" className="SHARED-Content SHARED-PageWrapper">
                    {Header_F}
                </div>
                <Footer />
            </HelmetProvider>
        )
    }
};

export default Neauxbles;