import Audio from './Assets/Audio/Audio';

const Helpers = {
    ChannelPointAnalyzer: function (data, cb) {
        data.msg = null;
        switch (data.type) {
            case 'confetti':
                data.msg = 'Confetti!';
                break;

            case 'posture':
                data.msg = 'Posture Check!';
                break;

            case 'hydrate':
                data.msg = 'Hydrate!';
                break;

            case 'gun':
                data.msg = 'Gun Game!';
                break;

            case 'character':
                data.msg = 'Custom Character!';
                break;

            case 'drop':
                data.msg = 'Drop It!';
                break;

            case 'beanboozled':
                data.msg = 'Beanboozled!';
                break;

            case 'timeout':
                data.msg = 'Timeout Someone!';
                break;

            case 'chug':
                data.msg = 'Chug Glass of Water!';
                break;

            case 'handCam':
                data.msg = 'Hand Cam Loading...';
                break;

            case 'fortnite':
                data.msg = '24HR FORTNITE STREAM UNLOCKED!';
                break;

            default:
                break;
        }
        cb(data);
    },

    eventDataDefault: {
        type: null,
        username: null,
        amount: null,
        months: null,
        viewers: null,
        sub_plan: null,
        streak_months: null,
        message: null,
        function: null,
        Audio: {
            url: Audio.Default.url,
            vol: Audio.Default.vol
        }
    },

    EventAnalyzer: async function (data, cb) {
        let eventData = this.eventDataDefault;
        eventData.type = data.type;

        switch (data.type) {
            case "bit":
            case "bits":
                eventData.username = data.username;
                eventData.amount = data.amount;
                eventData.message = data.message;
                eventData.Audio = Audio.Bits;
                eventData.title = data.amount + (data.amount == 1 ? ' Bit Cheered' : ' Bits Cheered');
                break;

            case "donation":
            case "tiltify":
                eventData.username = data.username;
                eventData.amount = data.amount;
                eventData.message = data.message;
                eventData.Audio = Audio.Sub;
                eventData.title = data.amount + (data.type == 'donation' ? ' Tip' : ' Charity Donation');
                break;

            case "follow":
                eventData.username = data.username;
                eventData.Audio = Audio.Default;
                eventData.message = null;
                eventData.title = 'New Neauxble Follower';
                break;

            case "host":
            case "raid":
                eventData.username = data.username;
                eventData.viewers = data.viewers;
                eventData.Audio = Audio.Default;
                eventData.message = null;
                eventData.title = data.viewers + (data.type == 'host' ? ' Viewer Host' : ' Viewer Raid');
                break;

            case "merch":
                eventData.username = data.username;
                eventData.amount = data.amount;
                eventData.message = data.message;
                eventData.Audio = Audio.Default;
                eventData.title = 'New Merch Acquired';
                break;

            case "resub":
            case "subscription":
                eventData.username = data.username;
                eventData.months = data.months;
                eventData.message = data.message;
                eventData.sub_plan = data.sub_plan;
                eventData.streak_months = data.streak_months;
                eventData.Audio = Audio.Sub;
                if (data.type == 'resub' || data.months >= 2) {
                    eventData.title = data.months + ' Month Resubscriber';
                } else {
                    eventData.title = (data.sub_plan == 'Prime' ? 'New Prime Neauxble Subscriber' : 'New Neauxble Subscriber');
                }
                break;

            default:
                break;
        }
        cb(eventData);
    }

}

export default Helpers;