import React, { useEffect, useState } from 'react';
import ApiHandler from '../../Api/ApiHandler';
import Img from '../img/Images';
import '../css/WebsiteShared.css';
import '../../Shared/Common.css';
import './Header.css';

const Header = ({ mobile }) => {
    import('../css/LazyLoadShared.css');
    const Api = new ApiHandler('site');
    const [isLive, setLive] = useState(true);
    const [menuOpen, setMenuOpen] = useState(false);
    const page = ((window.location.pathname).slice(1).split('/')[0]).toLocaleLowerCase();
    useEffect(() => {
        Api.Listener((response) => {
            if (response.api === 'isLive') {
                setLive(response.data.isLive)
            }
            // console.log(response);
        });
        Api.Get('isLive');
    }, []);


    const Menu_F = (
        <React.Fragment>
            <ul>
                <a href="/home"><li className={page === 'home' ? 'MENU-Selected' : ''}>Home</li></a>
                <a href="/neauxbles"><li className={page === 'neauxbles' ? 'MENU-Selected' : ''}>Neauxbles</li></a>
                <a href="/charity"><li className={page === 'charity' ? 'MENU-Selected' : ''}>Charity</li></a>
                <a href="/commands"><li className={page === 'commands' ? 'MENU-Selected' : ''}>Commands</li></a>
                <a href="/contact"><li className={page === 'contact' ? 'MENU-Selected' : ''}>Contact</li></a>
                {page === 'changelog' ? (<a href="/changelog"><li className="MENU-Selected">ChangeLog</li></a>) : ''}
                {page === 'links' ? (<a href="/links"><li className="MENU-Selected">Links</li></a>) : ''}
                {page === 'onlyfans' ? (<a href="/onlyfans"><li className="MENU-Selected">OnlyFans</li></a>) : ''}
                {page === 'socials' ? (<a href="/socials"><li className="MENU-Selected">Socials</li></a>) : ''}
                {page === 'tips' ? (<a href="/tips"><li className="MENU-Selected">Tips</li></a>) : ''}
            </ul>
        </React.Fragment>
    );

    const Socials_F = (
        <React.Fragment>
            <a href="/twitter"><img src={Img.Twitter} alt="Twitter" /></a>
            <a href="/instagram"><img src={Img.Instagram} alt="Instagram" /></a>
            <a href="/discord"><img src={Img.Discord} alt="Discord" /></a>
            <a href="/tiktok"><img src={Img.Tiktok} alt="Tiktok" /></a>
            <a href="/youtube"><img src={Img.Youtube} alt="Youtube" /></a>
        </React.Fragment>
    );

    if (mobile === true) {
        return (
            <div id="HEADER-Body">
                <div id="HEADER_MOBILE-MenuWrapper">
                    <a href="/home"><img src={Img.HeaderMobile} alt="NeauxPro" className="headerMobile" /></a>
                    {isLive === false ? (
                        <a href="/live"><div id="HEADER_MOBILE-LiveNow">
                            <div className="circle"></div>
                            <p>LIVE</p>
                        </div></a>
                    ) : ''}
                    <img src={menuOpen ? Img.MenuOpen : Img.MenuClosed} onClick={() => { setMenuOpen(!menuOpen); }} className="menuControl" />
                </div>
                <div id="HEADER_MOBILE-Menu" className={menuOpen ? '' : 'SHARED-Hide'}>
                    {Menu_F}
                    <div id="HEADER_MOBILE-Socials">
                        {Socials_F}
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div id="HEADER-Body">
                <div id="HEADER-MenuWrapper">
                    <div id="HEADER-Menu" className="SHARED-PageWrapper">
                        <a href="/home"><img src={Img.Header} alt="NeauxPro" /></a>
                        {Menu_F}
                    </div>
                </div>
                <div id="HEADER-SubMenu" className="SHARED-PageWrapper">
                    {isLive === false ? (
                        <a href="/live"><div id="HEADER-LiveNow">
                            <div className="circle"></div>
                            <p>LIVE - Join the Stream</p>
                        </div></a>
                    ) : ''}
                    <div id="HEADER-Socials">
                        {Socials_F}
                    </div>
                </div>
            </div>
        )
    }
};

export default Header;