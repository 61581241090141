import React, { Fragment, useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from './Shared/Header';
import Footer from './Shared/Footer';
import CharityInfo from './Components/CharityInfo';
import './css/Charity.css';
import campaigns from './Components/CharityInfo';

const Title_F = (
    <React.Fragment>
        <Helmet>
            <title>NeauxPro – Charity</title>
        </Helmet>
    </React.Fragment>
)

const Header_F = (
    <React.Fragment>
        <h3>Charity & Fundraising</h3>
    </React.Fragment>
)

const SubHeader_F = (
    <React.Fragment>
        <h4>With the generosity of Neauxbles, over <span className="highlighted">$2,586</span> has been raised for several different charities since Janurary 2019.</h4>
    </React.Fragment>
)

const P1_F = (
    <React.Fragment>
        <p>With a mission to, not only provide a fun an entertaining space on the internet. I want to bring awareness to a vast array of different charities and causes around the world.</p>
    </React.Fragment>
)

const Charities_F = CharityInfo.map(campaign => <div id="CHARITY-Campaign" key={campaign.key}>
    <div id="CHARITY-Campaign-Raised">{campaign.raised}</div>
    <div id="CHARITY-Campaign-Title">{campaign.title}</div>
    <div id="CHARITY-Campaign-Info">{campaign.info}</div>
    {campaign.url != null ? (<p><a href={campaign.url}>Website</a></p>) : ''}
</div>);

const Content_F = (
    <React.Fragment>
        {Header_F}
        {SubHeader_F}
        {P1_F}
        {Charities_F}
    </React.Fragment>
)



const Charity = ({ mobile }) => {
    if (mobile === true) {
        return (
            <HelmetProvider>
                {Title_F}
                <Header mobile={mobile} />
                <div id="CHARITY-Body" className="SHARED-Content">
                    {Content_F}
                </div>
                <Footer />
            </HelmetProvider>
        )
    } else {
        return (
            <HelmetProvider>
                {Title_F}
                <Header mobile={mobile} />
                <div id="CHARITY-Body" className="SHARED-Content SHARED-PageWrapper">
                    {Content_F}
                </div>
                <Footer />
            </HelmetProvider>
        )
    }
};

export default Charity;