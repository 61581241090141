const Common = {
    NullOrEmptyData: function (data, singleQuotes = false) {
        if (data === '' || data === undefined) {
            return null;
        } else {
            return singleQuotes ? `'${data}'` : data;
        }
    },

    LoopWait: (s = 5) => new Promise(r => setTimeout(r, (s * 1000))),

    PixelGradient: function (gridInput, rowsInput, leftToRight) {
        var grid = gridInput,
            rows = rowsInput,
            r = 0,
            k = 0,
            results = Array();
        while (r < rows) {
            if (leftToRight) {
                var p = grid;
                while (p > 0) {
                    var tenth = p / grid;
                    if (p < grid / 10) {
                        var randomNumber = (Math.random() * tenth);
                    } else {
                        var randomNumber = (Math.random() * tenth) + (tenth - .1);
                    }

                    var opacity = { opacity: + randomNumber.toFixed(2) },
                        temp = (<div style={opacity} key={k} />);
                    results.push(temp);
                    p--;
                    k++;
                }
            } else {
                var p = 0;
                while (p < grid) {
                    var tenth = p / grid;
                    if (p < grid / 10) {
                        var randomNumber = (Math.random() * tenth);
                    } else {
                        var randomNumber = (Math.random() * tenth) + (tenth - .1);
                    }

                    var opacity = { opacity: + randomNumber.toFixed(2) },
                        temp = (<div style={opacity} key={k} />);
                    results.push(temp);
                    p++;
                    k++;
                }
            }
            r++;
        }
        return results;
    },

    LeftPixelGradient: function (gridInput, rowsInput) {
        return this.PixelGradient(gridInput, rowsInput, true);
    },

    RightPixelGradient: function (gridInput, rowsInput) {
        return this.PixelGradient(gridInput, rowsInput, false);
    }
}

export default Common;